import {http, param} from '../../utils/http'
import conf from '../../config'

export const API_ROOT = `${conf.API_URL}`

const templateActionService = {
    findMetaSupplier() {
        return http.get(`${API_ROOT}/supplier/templateAction/meta-supplier`).then(resp => {
            return resp
        })
    },
    //Provider Internal Actions
    findInternalActions() {
        return http.get(`${API_ROOT}/supplier/templateAction/internal-actions-meta`).then(resp => {
            return resp
        })
    },
    saveInternalActions(payload) {
        return http.post(`${API_ROOT}/supplier/templateAction/save-actions-meta`,payload).then(resp => {
            return resp
        })
    },
    getRecallMetaData () {
        return http.get(`${API_ROOT}/supplier/templateAction/internal-description-meta`).then(resp => {
            return resp
        })
    },
}

export default templateActionService
